@import "./style/index.scss";

.text-primary {
  color: #ffc558;
}
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
