.question-answer {
  margin-top: 10px;

  @media screen and (min-width: 1800px) {
    font-size: 24px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 1.3;
  }
  &__question {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      cursor: pointer;
      margin-top: -11px;
      @media screen and (min-width: 1800px) {
        margin-top: -15px;
      }

      @media screen and (max-width: 767px) {
        margin-top: -15px;
      }
    }

    div {
      margin-left: 10px;
    }
  }

  &__answer {
    margin-top: 5px;
    transition: all 0.4s;
    max-height: 0;
    overflow: hidden;
  }
}
