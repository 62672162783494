.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  &__logo {
    width: 300px;
    @media screen and (max-width: 400px) {
      width: 260px;
    }
    @media screen and (min-width: 1900px) {
      width: 500px;
    }
  }
  &__text {
    color: rgb(4, 249, 196);
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 1.3;
    }
    @media screen and (min-width: 1900px) {
      font-size: 24px;
    }
  }
}
