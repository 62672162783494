body {
  margin: 0;
  font-family: "AstroSpace";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

@font-face {
  font-family: "SUPER LEGEND BOY";
  src: url("./assets/font/SuperLegendBoy-4w8Y.ttf");
}

@font-face {
  font-family: "Minecraft";
  src: url("./assets/font/Minecraftia-Regular.ttf");
}

@font-face {
  font-family: "dogica";
  src: url("./assets/font/dogica.ttf");
}

@font-face {
  font-family: "AstroSpace";
  src: url("./assets/font/AstroSpace.ttf");
}
