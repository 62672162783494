.navbar {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  transition: all 0.4s;
  &:hover {
    background-color: rgba(84, 84, 84, 0.5);
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px 5%;
    @media screen and (max-width: 1800px) {
      padding: 20px 5%;
    }

    &__nav-items {
      display: flex;
      align-items: center;
      &__nav-item {
        font-family: "Press Start 2P", cursive;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 42px;
        color: rgb(4, 249, 196);
        margin-right: 25px;
        cursor: pointer;
        @media screen and (max-width: 1800px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1100px) {
          font-size: 16px;
        }
        @media screen and (max-width: 960px) {
          font-size: 12px;
          margin-right: 18px;
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__social-icons {
      a {
        margin-left: 20px;
        img {
          width: 50px;

          @media screen and (max-width: 1800px) {
            width: 40px;
          }
        }
      }
    }
  }

  &__sidebar {
    position: fixed;
    background: #000000;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 100%;
    transform-origin: right center;
    transform: scaleX(0);
    transition: all 0.5s;
    font-size: 32px;
    z-index: 99;
    width: 100%;
    &__nav-item {
      color: #ffffff;
      text-decoration: none;
      margin-bottom: 10px;
    }
  }
}
