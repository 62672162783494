.page-home {
  &__minting-soon {
    background: url("../../assets//images/background/sprite_0.png");
    background-size: 100% 100%;
    padding: 200px 5%;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        max-width: 800px;
        @media screen and (max-width: 1800px) {
          max-width: 680px;
        }
        @media screen and (max-width: 1440px) {
          max-width: 640px;
        }
        @media screen and (max-width: 1380px) {
          max-width: 600px;
        }
        @media screen and (max-width: 1330px) {
          max-width: 500px;
        }
        @media screen and (max-width: 1100px) {
          max-width: 400px;
        }
        @media screen and (max-width: 970px) {
          max-width: 280px;
        }
        @media screen and (max-width: 767px) {
          max-width: 400px;
        }
        @media screen and (max-width: 500px) {
          max-width: 300px;
        }
      }
      &__img {
        width: 380px;
        @media screen and (max-width: 1900px) {
          width: 300px;
        }
        @media screen and (max-width: 1250px) {
          width: 250px;
        }
        @media screen and (max-width: 1023px) {
          width: 200px;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &__text {
      color: rgb(4, 249, 196);
      font-size: 44px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 40px;

      @media screen and (max-width: 400px) {
        font-size: 24px;
      }
    }
  }

  &__collection {
    background: url("../../assets/images/background/sprite_1.png");
    background-size: 100% 100%;
    padding: 30px 0;

    &__carousel {
      .slick-slide {
        div {
          padding: 15px;
          cursor: pointer;
        }
      }
    }

    &__text {
      color: rgb(4, 249, 196);
      font-size: 20px;
      text-align: center;
      margin-top: 85px;
      padding: 0 5%;
      line-height: 3;
      @media screen and (min-width: 1900px) {
        padding: 0 10%;
        font-size: 24px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
  }

  &__metaverse {
    background: url("../../assets/images/background/sprite_2.png");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding: 70px 5%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    &__content {
      margin-right: 20px;

      &__title {
        color: #ffffff;
        font-size: 44px;
        text-transform: uppercase;
        text-shadow: 1px 1px 6px purple;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 1.5;
        }
      }

      &__text {
        color: rgb(4, 249, 196);
        font-size: 18px;
        line-height: 3;

        @media screen and (min-width: 1900px) {
          font-size: 24px;
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }

    &__image {
      img {
        width: 300px;
        @media screen and (max-width: 950px) {
          width: 250px;
        }
        @media screen and (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }
  }

  &__galactic-alliance {
    background-color: rgb(57, 13, 22);
    text-align: center;
    padding: 20px 10px;

    &__title {
      color: #ffffff;
      font-size: 44px;
      text-transform: uppercase;
      text-shadow: 1px 1px 6px purple;
      margin-bottom: -15px;
      margin-top: 5px;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 12px;
        margin-top: 7px;
      }
    }

    &__bring-text {
      color: rgb(4, 249, 196);
      margin: 0 0 20px 0;

      @media screen and (min-width: 1900px) {
        font-size: 20px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 1.3;
      }
    }

    &__announce-text {
      color: rgb(4, 249, 196);
      font-size: 20px;

      @media screen and (min-width: 1900px) {
        font-size: 22px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 1.3;
      }
    }
  }

  &__utility {
    background: url("../../assets/images/background/sprite_0.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 5%;
    position: relative;

    &__title {
      color: #ffffff;
      font-size: 44px;
      text-transform: uppercase;
      text-shadow: 1px 1px 6px purple;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &__lists {
      max-width: 70%;
      margin-top: 0;
      &__list {
        color: rgb(4, 249, 196);
        font-size: 20px;
        margin-bottom: 20px;
        list-style: none;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 8px;
          background-color: rgb(4, 249, 196);
          left: -20px;

          @media screen and (min-width: 1900px) {
            top: 12px;
          }
          @media screen and (max-width: 767px) {
            top: 5px;
          }
        }
        @media screen and (min-width: 1900px) {
          font-size: 24px;
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 1660px) {
        max-width: 65%;
      }
      @media screen and (max-width: 834px) {
        max-width: 60%;
      }
      @media screen and (max-width: 767px) {
        max-width: 768px;
      }
    }

    &__ship {
      position: absolute;
      width: 300px;
      right: 10%;
      top: 0;
      @media screen and (max-width: 1312px) {
        right: 2%;
        width: 300px;
      }
      @media screen and (max-width: 1200px) {
        right: 2%;
        width: 250px;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__roadmap {
    background: url("../../assets/images/background/sprite_1.png");
    background-size: 100% 100%;
    padding: 100px 5%;

    &__title {
      color: #ffffff;
      text-shadow: 1px 1px 6px purple;
      font-size: 44px;
      text-transform: uppercase;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      color: rgb(4, 249, 196);

      &__img {
        width: 300px;
        margin-right: 30px;
        margin-top: 50px;

        @media screen and (max-width: 1023px) {
          width: 200px;
        }
        @media screen and (max-width: 768px) {
          width: 150px;
        }
        @media screen and (max-width: 600px) {
          width: 100px;
        }
        @media screen and (max-width: 500px) {
          width: 50px;
        }
        @media screen and (max-width: 374px) {
          margin-right: 5px;
        }
      }

      &__progress-bar-wrap {
        background-color: rgb(84, 84, 84);
        width: 6px;
        margin-top: 50px;
        margin-bottom: 180px;

        @media screen and (max-width: 1900px) {
          margin-bottom: 160px;
        }

        @media screen and (max-width: 544px) {
          margin-bottom: 180px;
        }

        @media screen and (max-width: 500px) {
          margin-top: 30px;
        }

        @media screen and (max-width: 426px) {
          margin-bottom: 210px;
        }

        @media screen and (max-width: 404px) {
          margin-bottom: 160px;
        }

        @media screen and (max-width: 386px) {
          margin-bottom: 180px;
        }

        @media screen and (max-width: 374px) {
          margin-bottom: 160px;
        }

        @media screen and (max-width: 358px) {
          margin-bottom: 170px;
        }

        @media screen and (max-width: 337px) {
          margin-bottom: 200px;
        }

        @media screen and (max-width: 325px) {
          margin-bottom: 210px;
        }

        &__progress-bar {
          width: 100%;
          background-color: rgb(4, 249, 196);
        }
      }

      &__card {
        background-color: rgba(18, 137, 207, 0.5);
        padding: 20px;
        margin-left: 30px;
        width: 800px;

        @media screen and (min-width: 1900px) {
          padding: 20px 100px;
        }

        @media screen and (max-width: 500px) {
          padding: 10px 10px;
        }

        &__item {
          position: relative;
          margin-top: 20px;

          @media screen and (max-width: 620px) {
            margin-top: 0px;
          }

          &__progressStop {
            position: absolute;
            content: "";
            top: 10px;
            left: -53px;
            transform: translateX(-50%);
            width: 30px;
            height: 30px;
            background-color: rgb(4, 249, 196);
            border-radius: 50%;
            @media screen and (min-width: 1900px) {
              left: -134px;
            }
            @media screen and (max-width: 500px) {
              width: 20px;
              height: 20px;
              left: -41px;
            }
          }
          &__title {
            font-size: 25px;
            font-weight: 700;
            @media screen and (max-width: 700px) {
              font-size: 20px;
            }
            @media screen and (max-width: 620px) {
              font-size: 16px;
            }
            @media screen and (max-width: 405px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1900px) {
              font-size: 32px;
            }
          }
          &__content {
            @media screen and (max-width: 685px) {
              margin-top: 10px;
              margin-bottom: 10px;
            }
            @media screen and (max-width: 620px) {
              margin-top: 3px;
              margin-bottom: 3px;
            }

            &__list {
              font-size: 16px;
              margin-top: 10px;
              list-style: none;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                top: 7px;
                background-color: rgb(4, 249, 196);
                left: -20px;

                @media screen and (max-width: 850px) {
                  top: 5px;
                }
                @media screen and (max-width: 600px) {
                  top: 6px;
                  height: 8px;
                  width: 8px;
                }
                @media screen and (max-width: 405px) {
                  top: 4px;
                }

                @media screen and (min-width: 1900px) {
                  top: 9px;
                }
              }

              @media screen and (max-width: 850px) {
                font-size: 14px;
              }
              @media screen and (max-width: 600px) {
                font-size: 14px;
              }
              @media screen and (max-width: 405px) {
                font-size: 12px;
              }

              @media screen and (min-width: 1900px) {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  &__faq {
    background: url("../../assets/images/background/sprite_2.png");
    background-size: 100% 100%;
    padding: 20px 5%;
    margin-bottom: auto;

    @media screen and (min-width: 1900px) {
      padding: 20px 10%;
    }

    &__title {
      color: #ffffff;
      font-size: 44px;
      text-transform: uppercase;
      text-shadow: 1px 1px 6px purple;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &__content {
      display: flex;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      &__lists {
        background-color: rgba(18, 137, 207, 0.5);
        padding: 20px;
        width: 100%;
        margin-right: 50px;
        color: rgb(4, 249, 196);
        margin-bottom: auto;

        @media screen and (max-width: 767px) {
          margin-right: 0;
          width: auto;
        }
      }
      &__img {
        display: flex;
        justify-content: center;
        img {
          width: 300px;
          @media screen and (max-width: 950px) {
            width: 250px;
          }
          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &__team {
    color: white;
    padding: 100px 3%;
    background-image: url("../../assets/images/background/sprite_3.png");
    background-size: 100% 100%;

    &__title {
      text-align: center;
      font-size: 44px;
      text-transform: uppercase;
      text-shadow: 1px 1px 6px purple;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &__content {
      display: flex;
      justify-content: space-around;
      position: sticky;
      -webkit-position: sticky;
      top: 200px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      a {
        text-decoration: none;
        color: #ffffff;
      }

      img {
        width: 300px;
        @media screen and (max-width: 950px) {
          width: 250px;
        }
        @media screen and (max-width: 767px) {
          margin-top: 20px;
        }
      }

      &__card {
        text-align: center;
        transform: scale(1);
        transition: all 0.4s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &__faction {
    background-image: url("../../assets/images/background/sprite_0.png"),
      url("../../assets/images/background/sprite_1.png");
    background-position: top, bottom;
    background-size: 100% 50%, 100% 50.1%;
    background-repeat: no-repeat;
    &__title {
      color: #ffffff;
      font-size: 44px;
      text-transform: uppercase;
      background-color: rgb(57, 13, 22);
      text-shadow: 1px 1px 6px purple;
      text-align: center;
      padding: 33px 0 0;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.5;
        padding: 16px 0 0;
      }
    }

    &__content {
      padding: 20px 5%;
      &__item {
        display: flex;
        margin-top: 30px;
        @media screen and (max-width: 600px) {
          flex-direction: column;
        }

        &__img {
          img {
            width: 300px;
            @media screen and (max-width: 950px) {
              width: 250px;
            }
          }
        }

        &__description {
          margin-left: 20px;
          color: rgb(4, 249, 196);

          &__title {
            font-size: 24px;
            margin-bottom: 20px;

            @media screen and (max-width: 767px) {
              font-size: 18px;
              line-height: 1.3;
            }
            @media screen and (min-width: 1900px) {
              font-size: 32px;
            }
          }

          &__content__text {
            line-height: 2;
            margin-top: 20px;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1900px) {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
